<template>
  <div class="notice_list_wrap">
    <div class="notice_list" v-for="(data,index) in boardData" :key="`board${index}`"
         @click="moveDetail(`${data.wr_id}`)">
      <button>
        <div class="notice_title">{{index+1}}</div>
<!--        상태에 따라 wait / finish 구현 예정 -->
        <div class="notice_content finish" :class="{finish: statusCheck}" v-if="data.Comment.cnt === 1"> {{ $t('answer_finish') }}</div>
        <div class="notice_content wait" :class="{wait: !statusCheck}" v-else>{{ $t('answer_wait') }}</div>
        <div class="notice_text">{{ data.wr_subject}}</div>
        <div class="notice_date">{{ data.created_at }}</div>
      </button>
    </div>
    <div class="qna_bottom">
      <button class="black-btn" @click="movePage('/qna/create')">{{$t('question')}}</button>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "BoardListQnaLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}
    },
  },
  data() {
    return{
      statusCheck: 'wait',
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(data){
      this.$router.push(`/qna/${data}`)
    },
    movePage(url) {
      this.$router.push(url);
    },
    tableIdx(index) {
      return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>